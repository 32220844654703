@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap);
/* base styles */
html,
body,
#root {
    height: 100%;
}
body {
    font-family: Raleway, sans-serif;
    margin: 0;
    font-size: 1.4em;
    background: #dfdfdf;
    color: #333;
}
h1,
h2,
h3,
p {
    margin: 0;
}
input,
textarea {
    color: #333;
    padding: 8px;
    font-size: 1em;
    border-radius: 4px;
    border: 1px solid #d4d4d4;
    background-color: #fff;
    display: block;
    width: 100%;
    box-sizing: border-box;
}
.page-title {
    text-align: center;
    margin: 40px auto;
    color: #333;
}
.loading,
.error,
.message {
    text-align: center;
    margin: 40px auto;
}

.searchbar form {
    display: flex;
    align-items: center;
}
.searchbar input {
    margin-left: 10px;
}

.navbar {
    background: #58249c;
    padding: 20px;
    color: #fff;
}
.navbar nav {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}
.navbar a.brand {
    margin-right: auto;
    color: #fff;
    text-decoration: none;
}
.navbar a:last-child {
    color: #fff;
    text-decoration: none;
    margin-left: 40px;
    padding: 8px;
    border: 1px solid #fff;
    border-radius: 4px;
}
.navbar a:last-child:hover {
    background: #fff;
    color: #333;
}

.home {
    max-width: 1200px;
    margin: 60px auto;
}

.home.dark {
    color: #e4e4e4;
}

.recipe-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
    max-width: 1200px;
    margin: 40px auto;
}
.recipe-list .card {
    background: #fff;
    color: #333;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    position: relative;
}
.recipe-list .card:hover {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg);
}
.recipe-list .card h3 {
    color: #555;
    margin-bottom: 6px;
}
.recipe-list .card p {
    color: #999;
    font-size: 0.9em;
}
.recipe-list .card div {
    color: #555;
    font-size: 0.7em;
    margin: 20px 0;
    line-height: 1.5em;
}
.recipe-list .card a {
    color: #555;
    text-decoration: none;
    display: block;
    background: #e2e2e2;
    font-size: 0.9em;
    width: 100px;
    text-align: center;
    width: 120px;
    padding: 8px;
    border-radius: 4px;
    margin: 20px auto 0;
}

.delete {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    -webkit-filter: invert(60%);
            filter: invert(60%);
}

/* dark mode */
.recipe-list .card.dark {
    background: #555;
}
.recipe-list .card.dark p,
.recipe-list .card.dark h3,
.recipe-list .card.dark div {
    color: #e4e4e4;
}

.create {
    color: #555;
    max-width: 480px;
    margin: 60px auto;
}
.create label span {
    display: block;
    margin: 30px 0 10px;
}
.create p {
    margin-top: 10px;
    font-size: 0.8em;
}
.create button {
    display: block;
    width: 140px;
    font-size: 1em;
    color: #fff;
    padding: 8px 20px;
    border: 0;
    border-radius: 4px;
    background-color: #58249c;
    cursor: pointer;
    text-decoration: none;
    margin: 20px auto;
}
.ingredients {
    display: flex;
    align-items: center;
}
.ingredients button {
    margin: 0 0 0 10px;
}

.create.dark {
    color: #e4e4e4;
}
.dark .page-title {
    color: #e4e4e4 !important;
}


.recipe {
    max-width: 1200px;
    margin: 40px auto;
    text-align: center;
    background: #fff;
    padding: 40px;
    box-sizing: border-box;
}
.recipe ul {
    display: flex;
    padding: 0;
    justify-content: center;
    margin-top: 0;
}
.recipe li {
    list-style-type: none;
    margin-right: 10px;
    color: #777;
}
.recipe li::after {
    content: ",";
}
.recipe li:last-child::after {
    content: ".";
}
.method {
    text-align: left;
    line-height: 1.5em;
}

/* dark mode */
.recipe.dark {
    background: #555;
    color: #e4e4e4;
}

.theme-selector {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: 1200px;
    margin: 20px auto;
}
.theme-buttons div {
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: 15px;
    border-radius: 50%;
}
.mode-toggle {
    margin-right: auto;
}
.mode-toggle img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.App {
    min-height: 100%;
}
.App.dark {
    background: #333;
}

